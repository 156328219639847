// @flow
import React from 'react'

import { Store } from 'Store'
import { Currency } from 'i18n/NumberFormatter'
import * as Form from 'components/Form'
import DonateOption from 'components/DonateOption'

import styles from './style.module.scss'

type Props = {
    selectedAmount: ?number,
    donationDesignations: Array<string>,
    handleFieldChange: (string, any) => void,
    t: string => string,
}
export default function DonationAmountField (props: Props) {
    const { state: { locale, currency, campaign } } = React.useContext(Store)

    const [otherAmountActive, setOtherAmountActive] = React.useState(null)

    const defaultDonationAmount = props.selectedAmount || campaign.website.defaultDonationAmount
    const donationAmountOptions = campaign.website.donationAmounts

    const handleSetOtherAmountActive = React.useCallback(() => {
        setOtherAmountActive(true)
    }, [])

    const handleSetOtherAmountDisable = React.useCallback(() => {
        setOtherAmountActive(false)
    }, [])

    React.useEffect(() => {
        if ((!donationAmountOptions.includes(defaultDonationAmount)) && defaultDonationAmount === null) handleSetOtherAmountActive()
        if (defaultDonationAmount) props.handleFieldChange('donationAmount', defaultDonationAmount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultDonationAmount, donationAmountOptions, handleSetOtherAmountActive, props.handleFieldChange])

    const currencyFormatter = React.useMemo(() => (
        Currency(currency, locale)
    ), [currency, locale])

    return (
        <Form.RowColumn size={1} className={styles.donationAmountsRow}>
            <Form.Field>
                <div className={styles.donationAmounts}>
                    {campaign.website.donationAmounts.map(donationAmount => (
                        <DonateOption
                            key={donationAmount}
                            center
                            small
                            title={currencyFormatter.format(donationAmount)}
                            onClick={() => {
                                // if a user changes the donation amount after he selected a designation
                                if (props.donationDesignations && props.donationDesignations.length === 1) {
                                    const designation = props.donationDesignations[0]
                                    designation.value = donationAmount
                                    handleSetOtherAmountDisable()
                                    // update first field, use callback to update extra state
                                    props.handleFieldChange('donationAmount', donationAmount, () => {
                                        props.handleFieldChange('donationDesignations', [designation])
                                    })
                                } else {
                                    handleSetOtherAmountDisable()
                                    props.handleFieldChange('donationAmount', donationAmount)
                                }
                            }}
                            active={props.selectedAmount === donationAmount && !otherAmountActive}
                        />
                    ))}

                    <DonateOption
                        center
                        small
                        title="Other"
                        onClick={() => {
                            // if a user changes the donation amount after he selected a designation
                            if (props.donationDesignations && props.donationDesignations.length === 1) {
                                // prevent double click on "Other" value must not be null or 0
                                const designation = props.donationDesignations[0]
                                designation.value = props.selectedAmount
                                // update first field, use callback to update extra state
                                handleSetOtherAmountActive()
                                props.handleFieldChange('donationAmount', designation.value, () => {
                                    props.handleFieldChange('donationDesignations', [designation])
                                })
                            } else {
                                handleSetOtherAmountActive()
                                props.handleFieldChange('donationAmount', null)
                            }
                        }}
                        active={otherAmountActive}
                    />
                </div>
            </Form.Field>

            {((otherAmountActive === true || (props.selectedAmount && !donationAmountOptions.includes(props.selectedAmount)))) &&
                <Form.CurrencyField
                    placeholder={props.t('fields.donation')}
                    type="currency"
                    defaultValue={props.selectedAmount || ''}
                    onChange={value => {
                        // if a user changes the donation amount after he selected a designation
                        if (props.donationDesignations && props.donationDesignations.length === 1) {
                            const designation = props.donationDesignations[0]
                            designation.value = value
                            // update first field, use callback to update extra state
                            props.handleFieldChange('donationAmount', value, () => {
                                props.handleFieldChange('donationDesignations', [designation])
                            })
                        } else {
                            props.handleFieldChange('donationAmount', value)
                        }
                    }}
                    currency={currency}
                    locale={locale}
                    required={typeof props.selectedAmount !== 'undefined' && !campaign.website.donationAmounts.includes(props.selectedAmount)}
                />
            }
        </Form.RowColumn>
    )
}
