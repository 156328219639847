import React from 'react'
import { BackgroundImageBlock } from '@shift-marketing/shift-one-website-editor'

import useTheme from 'hook/useTheme'

import VideoPlayButton from 'components/VideoPlayButton'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

export default function HeaderImage () {
    const theme = useTheme(supportedThemes)

    if (!theme) return null

    return (
        <div className={styles.container}>
            <BackgroundImageBlock
                className={styles.bgImg}
                defaultPositionX={50}
                defaultPositionY={1}
                blockId="landing.header.backgroundImage"
                defaultImage={theme.image}
            />

            <VideoPlayButton/>
        </div>
    )
}
