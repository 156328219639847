// @flow
import React from 'react'

import * as Form from 'components/Form'

import { Store } from 'Store'

type Props = {
    selectedAmount: ?number,
    selectedDesignations: Array<{ type: string, value: number }>,
    handleFieldChange: { type: string, value: number } => void,
    t: string => string,
}

export default function DonationsDesignationsField (props: Props) {
    const { state: { campaign } } = React.useContext(Store)

    return (
        <>
            <Form.RowColumn size={1}>
                <Form.Field>
                    <Form.Dropdown
                        placeholder={'Select'}
                        options={campaign.website.donationDesignations}
                        required={true}
                        onChange={value => {
                            props.handleFieldChange('donationDesignations', (value !== '' && value !== null) ? [{
                                type: value,
                                value: props.selectedAmount,
                                other: value.indexOf('Other') === 0
                            }] : null)
                        }}
                        defaultValue={props.selectedDesignations
                            ? (props.selectedDesignations[0].type
                                    ? props.selectedDesignations[0].other
                                        ? 'Other'
                                        : props.selectedDesignations[0].type
                                    : null
                            )
                            : null
                        }
                    />
                </Form.Field>
            </Form.RowColumn>

            {(props.selectedDesignations?.[0].type.indexOf('Other') === 0 || props.selectedDesignations?.[0].other) &&
                <Form.RowColumn size={1}>
                    <Form.Field
                        required
                        defaultValue={props.selectedDesignations
                            ? (props.selectedDesignations[0].type
                                    ? props.selectedDesignations[0].type.indexOf('Other') === 0
                                        ? ''
                                        : props.selectedDesignations[0].type
                                    : null
                            )
                            : null
                        }
                        onChange={value => {
                            props.handleFieldChange('donationDesignations', (value !== '' && value !== null) ? [{
                                type: value,
                                value: props.selectedAmount,
                                other: true
                            }] : null)
                        }}
                        type='text'
                        placeholder={'Enter other designation'}
                    />
                </Form.RowColumn>
            }
        </>
    )
}
