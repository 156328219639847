// @flow
import * as React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { Autoplay, Navigation } from 'swiper'
import { useBlock } from '@shift-marketing/shift-one-website-editor'

import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'

import supportedThemes from './themes/__supportedThemes'

import propertiesSchema from './properties.schema.json'

function DonationSummary (props) {
    const { properties, ref, editMode } = useBlock('landing_stats', propertiesSchema)

    const itemRenderer = React.useCallback((index) => {
        return (
            <div key={index + 1} className={styles.item} style={{
                '--color': properties.descriptionColor,
                '--heading-color': properties.headingColor,
            }}>
                <div className={styles.itemContent} dangerouslySetInnerHTML={{ __html: `<${properties.headingFontStyle}>${properties.items?.[index].heading}</${properties.headingFontStyle.split(' ')[0]}>` }}/>
                <div className={styles.itemContent} dangerouslySetInnerHTML={{ __html: `<${properties.descriptionFontStyle}>${properties.items?.[index].description}</${properties.descriptionFontStyle.split(' '[0])}>` }}/>
            </div>
        )
    }, [properties])

    const swiperRef = React.useRef()
    React.useEffect(() => {
        swiperRef.current.update()
    }, [properties.items.length])

    return (
        <div className={styles.container} ref={ref} style={{ backgroundColor: properties.backgroundColor }}>
            <div className={styles.innerContainer} style={{
                '--active-bubble-color': properties.activeBubbleColor,
                '--bubble-color': properties.bubbleColor,
                '--swiper-theme-color': properties.descriptionColor,
                '--swiper-navigation-size': '32px'
            }}>
                <Swiper
                    modules={[Autoplay, Navigation]}
                    slidesPerView={1}
                    centerInsufficientSlides={true}
                    loop={editMode !== true && window.innerWidth < 1024 ? properties.items.length > 1 : properties.items.length > 3}
                    navigation={editMode !== true && window.innerWidth < 1024 ? properties.items.length > 1 : properties.items.length > 3}
                    autoplay={(properties.items.length > 1 && editMode !== true) ? {
                        'delay': 2000,
                        'disableOnInteraction': true,
                        'pauseOnMouseEnter': true
                    } : false}
                    grabCursor={true}
                    onSwiper={swiper => swiperRef.current = swiper}
                    allowTouchMove={!editMode}
                    breakpoints={{
                        1024: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    {properties.items && properties.items.map((testimonial, i) => (
                        <SwiperSlide key={i + 1}>
                            {itemRenderer(i)}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default withTheme(supportedThemes)(DonationSummary)
