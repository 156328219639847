// @flow
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { Autoplay, Pagination } from 'swiper'
import { useBlock } from '@shift-marketing/shift-one-website-editor'

import styles from './styles.module.scss'

import propertiesSchema from './properties.schema.json'

export default function Testimonials () {
    const { properties, ref, editMode } = useBlock('testimonials', propertiesSchema)

    const swiperRef = React.useRef()
    React.useEffect(() => {
        if (properties.items.length > 1) {
            swiperRef.current.enable()
        } else {
            swiperRef.current.disable()
        }

        swiperRef.current.update()
    }, [properties.items.length])

    return (
        <div className={styles.testimonials} ref={ref} style={{
            '--quote-color': properties.quoteColor,
            '--byline-color': properties.byLineColor,
            '--active-bubble-color': properties.activeBubbleColor,
            '--bubble-color': properties.bubbleColor,
            '--font-family': properties.font,
        }}>
            <Swiper
                modules={[Autoplay, Pagination]}
                slidesPerView={1}
                loop={properties.items && properties.items.length >= 1}
                pagination={{ clickable: true }}
                autoplay={(properties.items.length > 1 && editMode !== true) ? {
                    'delay': 2500,
                    'disableOnInteraction': true,
                    'pauseOnMouseEnter': true
                } : false}
                grabCursor={true}
                onSwiper={swiper => swiperRef.current = swiper}
                allowTouchMove={!editMode}
          >
                {properties.items && properties.items.map((testimonial, i) => (
                    <SwiperSlide key={i + 1}>
                        <span className={styles.testimonial}>
                            <div>
                                <div
                                    className={styles.quote}
                                    dangerouslySetInnerHTML={{
                                        __html: `<${properties.testimonialFontStyle}>${testimonial.quote}</${properties.testimonialFontStyle.split(' ')[0]}>`
                                    }}
                                />

                                <div
                                    className={styles.byLine}
                                    dangerouslySetInnerHTML={{
                                        __html: `<${properties.byLineFontStyle}>${testimonial.byLine}</${properties.byLineFontStyle.split(' ')[0]}>`
                                    }}
                                />
                            </div>
                        </span>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
