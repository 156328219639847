// @flow

import React from 'react'
import { useBlock, TextBlock } from '@shift-marketing/shift-one-website-editor'

import withTheme from 'hoc/withTheme'

import { createClickActivity } from 'Actions'
import { Store } from 'Store'

import formatPhoneNumber from 'util/formatPhoneNumber'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import schema from './properties.schema.json'

type Props = {}

function TopBar (props: Props) {
    const { properties, ref } = useBlock('topBar', schema)
    const { state } = React.useContext(Store)
    const formattedAddress = [
        ((state.campaign.client.address.line1 || '') + ' ' + (state.campaign.client.address.line2 || '')).trim(),
        state.campaign.client.address.city,
        state.campaign.client.address.province,
        state.campaign.client.address.postalCode
    ].join(' ')
    const formattedPhone = `&nbsp;| Ph: ${formatPhoneNumber(state.campaign.client.phone)}`
    const formattedEmail = `&nbsp;| Em: ${state.campaign.client.email}`

    return (
        <div
            ref={ref}
            className={styles.topBar}
            style={{
                color: props.theme.color,
                backgroundColor: properties.backgroundColor,
            }}
        >
            <div className={styles.container}>
                <TextBlock
                    blockId={'topbar.text'}
                    defaultContent={`
                        <p>
                            ${formattedAddress}
                            
                            ${state.campaign.client.phone ? (
                                `${formattedPhone}`
                            ): ''}
                            
                            ${state.campaign.client.email ? (
                                `${formattedEmail}`
                            ): ''}
                        </p>
                    `}
                    onAnchorClick={createClickActivity}
                />
            </div>
        </div>
    )
}

export default withTheme(supportedThemes)(TopBar)
