import React from 'react'
import { useBlock } from '@shift-marketing/shift-one-website-editor'

import { updateLead } from 'Actions'
import { Store } from 'Store'

import Header from 'components/Header'
import DonationSummary from 'components/DonationSummary'
import Footer from 'components/Footer'
import SideBar from 'components/SideBar'
import LeadGenForm from 'components/LeadGenForm'
import Payment from 'components/Payment'

import styles from './styles.module.scss'

import propertiesSchema from './properties.schema.json'

export default function Landing () {
    const { ref, properties } = useBlock('landing.body', propertiesSchema)
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)

    const handleAbort = React.useCallback(() => {
        setSubmitted(false)
    }, [])

    const initialValues = React.useMemo(() => {
        const values = state.lead.fields
        values.anonymousDonation = state.lead.fields.anonymousDonation || false
        values.donationType = state.lead.fields.donationType || 'one-time'

        // has no donation selected by the user
        if (!values.hasOwnProperty('donationAmount') || values.donationAmount === null) {
            // if we have defaultDonationAmount set it as default
            if (state.campaign.website.defaultDonationAmount) {
                values.donationAmount = state.campaign.website.defaultDonationAmount
            }
        }

        // if only 1 donationDesignation set is as default
        if (state.campaign.website.donationDesignations && state.campaign.website.donationDesignations.length === 1) {
            values.donationDesignations = [{
                type: state.campaign.website.donationDesignations[0],
                value: values.donationAmount
            }]
        }
        // if we have defaultDonationDesignation set is as default
        if (state.campaign.website.defaultDonationDesignation &&
            (typeof values.donationDesignations === 'undefined' || values.donationDesignations === null)
        ) {
            values.donationDesignations = [{
                type: state.campaign.website.defaultDonationDesignation,
                value: values.donationAmount
            }]
        }

        return values
    }, [
        state.campaign.website,
        state.lead.fields
    ])

    return (
        <>
            {submitted ? <Payment onAbort={handleAbort} /> : null}

            <Header/>

            <DonationSummary />

            <div ref={ref} className={styles.grid} style={{
                backgroundColor: properties.backgroundColor,
            }}>
                <div className={styles.leftSide}>
                    <LeadGenForm
                        onSubmit={async (data, done) => {
                            try {
                                let lead = {
                                    fields: data,
                                    isComplete: true
                                }
                                await updateLead(dispatch, state.code, lead)
                                setSubmitted(true)
                                window.scrollTo(0, 0)
                            } catch (err) {
                                alert('Oops something went wrong')
                                console.error(err)
                            } finally {
                                done()
                            }
                        }}
                        initialValues={initialValues}
                    />
                </div>

                <div className={styles.rightSide}>
                    <SideBar />
                </div>
            </div>

            <Footer />
        </>
    )
}
